import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { MDXProvider } from "@mdx-js/react"
import Terms from './terms.mdx'


const PrivacyPage = () => (
  <Layout>
    <div className="flex flex-col flex-wrap  mx-auto justify-center min-h-screen  w-4/5">

    <SEO title="Terms of Use" />
      <MDXProvider>
        <Terms />
      </MDXProvider>

    </div>
  </Layout>
)

export default PrivacyPage
